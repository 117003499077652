import React from "react";
import OurPartener from "../components/OurPartener";
import Img from "../assets/About/logoImg.jpeg";
import AboutPartner from "../components/AboutPartner";

const About = () => {
  return (
    <div className="container pb-5">
      <div className="">
        <h1 className="mt-5" style={{ textAlign: "left", color: "#224B0C" }}>
          About Us
        </h1>
        <p className="mt-4" style={{ textAlign: "left", color: "#224B0C" }}>
          Govind Charitable Society (Reg. No F-28559 ) is established in the
          Year 2019. Its main focus is to Create and Spread Skills Through
          Education for Social Awareness, Unity, Transformation and Integrity.
          It also focuses to create opportunities of education to the rural
          masses. It tries to inculcate new technological transformation through
          the use of ICT. It focuses on social integrity by eradicating social
          discrimination. It also works in the field of environmental awareness
          campaign
        </p>
      </div>
      <hr className="mt-5" /> 
      {/* <OurPartener /> */}
      <AboutPartner/>

      <div className="row">
        <div className="col-lg-6">
          <h1 className="mt-xl-5 mt-sm-0" style={{ textAlign: "left", color: "#224B0C" }}>
            Best School in Amravati
          </h1>
          <p className="mt-4" style={{ textAlign: "left", color: "#224B0C" }}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta
            ipsam sapiente, harum esse doloribus omnis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab explicabo ullam, porro accusantium doloribus est quae velit. Atque, praesentium libero? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum at numquam iure quis architecto fugit cumque quam inventore dolorum laboriosam.
          </p>
        </div>
        <div className="col-lg-6">
          <img className="img-fluid w-75 mt-sm-5 mt-xl-0" src={Img} alt="Logo img" />
        </div>
      </div>
    </div>
  );
};

export default About;
