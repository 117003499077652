import React from "react";
import school from "../assets/school.jpg";
import President from "../components/President";
import tale from "../assets/tale.png";
import shital from "../assets/shital.png";
import smita from "../assets/smita.png";
import Mission from "../components/Mission";

const Home = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h1
              className="mt-5"
              style={{ textAlign: "left", color: "#224B0C" }}
            >
              Welcome to Govind Charitable Society
            </h1>
            <p
              className="text-start mt-5"
              style={{ textAlign: "left", color: "#224B0C" }}
            >
              Govind Charitable Society (Reg. No F-28559 ) is established in the
              Year 2019. Its main focus is to Create and Spread Skills Through
              Education for Social Awareness, Unity, Transformation and
              Integrity. Nowadays every parent wants to give their child a good
              education. Parents are constantly striving for this, but
              opportunities for good education are not available in this rural
              area. The basic objective of Govind Charitable Society is to
              provide good and quality education to the students. Also it tries
              to provide environmental education for environmental awareness. It
              also tries to create communal harmony in the society and to create
              a progressive modern inclusive society by eradicating
              superstitions and for this the society is continuously striving.
            </p>
          </div>
          <div className="col-6">
            <img
              src={school}
              alt="School Image"
              width="500"
              loading="lazy"
              className="rounded-circle m-5"
            />
          </div>
        </div>
        <hr className="mt-5" />
        <div className="row">
          <div className="col-lg-12">
            <h2
              className="mt-4 mb-5"
              style={{ textAlign: "center", color: "#224B0C" }}
            >
              About Us
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-5 p-5 m-5 mt-0 border border-light rounded shadow p-3 mb-5 bg-white rounded">
            <h5 style={{ textAlign: "center", color: "#224B0C" }}>Vision</h5>
            <hr></hr>
            <p className="mt-4">
              To Create and Spread Skills Through Education for Social
              Awareness, Unity, Transformation and Integrity
            </p>
          </div>
          <div className="col-5 p-5 m-5 mt-0 border border-light rounded shadow p-3 mb-5 bg-white rounded">
            <h5 style={{ textAlign: "center", color: "#224B0C" }}>Mission</h5>
            <hr></hr>
            <ul className="mt-4" style={{ textAlign: "left" }}>
              <li className="text-decoration-none">
                To Create Education for Social Masses
              </li>
              <li>To Enhance Knowledge Transformation Through ICT </li>
              <li>To Eradicate Social Discrimination</li>
              <li>To Create Environmental Awareness</li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#224b0c" }}>
        <President />
      </div>
      <div className="container">
        <h2 className="m-5" style={{ textAlign: "center", color: "#224B0C" }}>
          Executive Members
        </h2>
        <hr />
        <div className="row">
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={tale}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                  Prof. Vikramaditya S. Tale
                </h5>
                <p className="card-text">(Vice - President)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={shital}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                  Dr. Shitalbabu A. Tayade
                </h5>
                <p className="card-text">(Secretary)</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={smita}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                  Dr. Smita S. Tayade
                </h5>
                <p className="card-text">(Treasurer)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#224b0c" }}>
        <h2 className="pt-4" style={{ color: "white"}}>
          Join our mission. Volunteer, Donate. Get Started Today.
        </h2>
        <hr style={{color:'white'}}></hr>
        <Mission />
      </div>
    </div>
  );
};

export default Home;
