import React from 'react'

const Footer = () => {
  return (
    <div className='d-flex align-items-center justify-content-center ' style={{backgroundColor:'#f5f5f5'}}>
        <p className='p-2 mt-4 text-black'>Design & Developed by Rohit Tayade</p>
    </div>
  )
}

export default Footer


