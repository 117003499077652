import React from 'react'

const AboutPartner = () => {
  return (
    <div className=''>
        <h1 className="mt-xl-5 mt-sm-0" style={{ textAlign: "left", color: "#224B0C" }}>
           Our Partener
        </h1>

        <div className="row">
            {/* card1 */}
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={{}}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                 Mr. Vaibhav Tayade
                </h5>
                <p className="card-text">(Member)</p>
              </div>
            </div>
          </div>

          {/* Card2 */}
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={{}}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                  Mr. Vaibhav Mondhe
                </h5>
                <p className="card-text">(Member)</p>
              </div>
            </div>
          </div>

          {/* Card3 */}
          <div className="col-lg-4">
            <div className="card p-2 m-4 border border-muted rounded ">
              <img
                className="card-img-top img-thumbnail"
                src={{}}
                height="310"
                alt="Card image cap"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ textAlign: "center", color: "#224B0C" }}
                >
                  Mr. Shubham Raut
                </h5>
                <p className="card-text">(Member)</p>
              </div>
            
          </div>
          </div>
        </div>
        <hr className="mt-5" /> 
    </div>
  )
}

export default AboutPartner