import React from "react";
import tatya from "../assets/tatya.jpeg";

const President = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2
              className="mt-3 pt-5"
              style={{ color: "#ffffff", textAlign: "left" }}
            >
              Meet Our President
            </h2>
            <hr className="" style={{ color: "#ffffff" }}></hr>
          </div>
          <div className="col-lg-6">
            <h3
              className="pt-4"
              style={{ color: "#ffffff", textAlign: "left" }}
            >
              Shri. Tatyasaheb Alias Ambadas G. Tayade
            </h3>
            <p className="m-5 ml-0 text-light" style={{ textAlign: "left" }}>
              "Atul Gopal is a pioneer of the ‘home delivery’ industry. In the
              summer of 69, his mom gave birth to Atul at her home in RDSO
              quarters in Lucknow. The family shifted to Pune when he was two
              years old. They haven’t shifted out yet. At the age of 4, he ran
              away from his first school. His next two schools, though, managed
              to hold on to him. College was at COEP, where he was unsure of
              which branch to join; started with Civil, moved on to Electrical
              and passed out in Mechanical. After COEP, he went to work with
              Tata Motors, as a Graduate Trainee. He recalls the two years spent
              there as an extension of COEP, where he did not do too much, but
              learnt a lot."
            </p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-column align-items-center justify-content-center pb-5">
              <img className="p-5" src={tatya} height="500" />
              <h5 style={{ color: "white" }}>
                Shri. Ambadas G. Tayade<br></br>
                <span className="pt-3">(President)</span>{" "}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default President;
