import React from 'react'
import Img from "../assets/contact/ContactImg.png";
import CallImg from "../assets/contact/Call.png";
import LocationImg from "../assets/contact/Location.png";
import MsjImg from "../assets/contact/Msj.png";
const ContactUs = () => {
  return (
    
    <>
    <div className='container'>
        <div className='pt-5 pb-2'>
            <h1  className="text-center pb-3"
                 style={{ color: "#224B0C" }}>Let’s get started</h1>
            <div className='container px-5'>
                <div className="row p-3">
                    <div className="col-lg-6">

                        <div className='pb-4'>
                            <input className='w-100 p-2 rounded-0 border border-dark' type="text" placeholder='First name....' />
                        </div>
                        <div className='pb-4'>
                            <input className='w-100 p-2 rounded-0 border border-dark' type="text" placeholder='Last name....' />
                        </div>
                        <div className='pb-4'>
                            <input className='w-100 p-2 rounded-0 border border-dark' type="text" placeholder='Work e-mail....' />
                        </div>
                        <div className='pb-4'>
                            <input className='w-100 p-2 rounded-0 border border-dark' type="text" placeholder='Phone no....' />
                        </div>

                    </div>
                    <div className="col-lg-6">
                        {/* <input className='w-100 h-100 rounded-0 border border-dark' type="text" placeholder='How we can help you?' /> */}
                        <textarea className='form-control rounded-0 border border-dark text-secondary' name="" id="" cols="30" rows="10">How we can help you?</textarea>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center pb-5'>
                <button className='btn d-flex justify-content-center rounded-0 w-50 px-5 shadow-lg rounded' style={{ backgroundColor: '#224B0C' }}><span style={{ color: '#fff', fontSize: '14px', fontWeight: '600' }}>Get in Touch</span></button>
            </div>
        </div>

        <div className="row p-5">
            <h1 className="text-center pb-3"
                 style={{ color: "#224B0C" }}>Contact us</h1>
            <div className="col-lg-6 d-lg-flex  justify-content-lg-start d-md-flex justify-content-md-center mt-5">
                <img className='img-fluid' src={Img} alt="Contact Image" />
            </div>
            <div className="col-lg-6 mt-5">
                <div className='d-flex p-2'>
                    <img className='h-25 object-fit-cover' src={LocationImg} alt="Location img" />
                    <p className='ml-3 mt-3' style={{ color: '#000000', fontSize: '14px', fontWeight: 500 }}>Govind Charitable Society , Shivrasik Nagar Kathora Road Amravati -444604</p>
                </div>
                <div className='d-flex p-2'>
                    <img className='img-fluid h-25' src={CallImg} alt="Call img" />
                    <p className='ml-3 mt-3' style={{ color: '#000000', fontSize: '14px', fontWeight: 500 }}>+91 9822527808</p>
                </div>
                <div className='d-flex p-2 align-items-center justify-content-start'>
                    <img className='img-fluid h-25' src={MsjImg} alt="Msj img" />
                    <p className='ml-3 mt-3' style={{ color: '#000000', fontSize: '14px', fontWeight: 500 }}>gocsamt@gmail.com</p>
                </div>
            </div>
        </div>

    </div>
</>
  )
}

export default ContactUs