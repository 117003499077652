import React from "react";

const Mission = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 pb-5">
          <h5 className="mt-5 mb-5" style={{ color: "white" }}>
            Email : <span>gocsamt@gmail.com</span>
          </h5>
          <button className="btn btn-outline-light">Browse Campaigns</button>
        </div>
        <div className="col-lg-6">
          <h5 className="mt-5 mb-5" style={{ color: "white" }}>
            Call Anytime : <span>9822527808</span>
          </h5>
          <button className="btn btn-outline-light">Get Involved</button>
        </div>
      </div>
    </div>
  );
};

export default Mission;
