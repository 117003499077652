import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from "../assets/logo-gocs.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="bg-light">
      <nav className="navbar navbar-expand-lg navbar-light bg-light container">
        <div className="container-fluid">
          <div className="navbar-brand">
            <Link to="/">
            <img src={logo} height="100" alt="MDB Logo" loading="lazy" />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex align-items-center" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <div className="nav-link active" aria-current="page" >
                 <Link className="nav-link active" to="/">
                  Home
                 </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link" >
                  <Link className="nav-link" to="about">
                  About us
                 </Link>
                </div>
              </li>
              <li className="nav-item">
                <div className="nav-link" >
                  <Link className="nav-link" to="contact">
                  Contact Us
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
